
import { Compiler, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { EUserType } from '../enums/user.enum';
import { ILogin } from '../interfaces/login.interface';
import { DataService } from './data.service';
import { RestService } from './rest.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService,
    private compiler: Compiler,
    private dataService: DataService,
    private router: Router) { }

  public forceLogout() {
    this.compiler.clearCache();
    // this.dataService.clearOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public login(data: any): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data).pipe(
        map((res: any) => res.response)
        , catchError((error) =>
          throwError(error))
      );
  }

  public logout(): Observable<IRestResponse<ILogin>> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }


  get isUser() {
    return this.dataService.getLocalStorageItem('user_type') == EUserType.USER
  }
}
